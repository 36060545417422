import React, { useState, useEffect } from "react";
import api from "../utils/api.utils";
import { TextField } from "@mui/material";

const partesSearch = ({ title, parte, setParte, setError, error }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const data = await api.buscaParte(searchTerm);
        setResults(data);
      } catch (error) {
        setError(error);
      }
    };

    if (searchTerm) {
      fetchSuggestions();
    } else {
      setResults([]);
    }
  }, [searchTerm, title]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    setParte(null); // Limpa o item selecionado quando o usuário digita
    setError("");
  };

  const handleSelectItem = (item) => {
    setError("");
    setParte(item);
    setShowParte(false);
    setSearchTerm(""); // Limpa o termo de pesquisa quando um item é selecionado
  };

  const [showParte, setShowParte] = useState(true);

  const cleanParte = () => {
    setShowParte(true);
    setParte(null);
  };

  return (
    <div>
      {showParte && !parte ? (
        <>
          <TextField
            label={`Buscar ${title}`}
            value={searchTerm}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          {results.length > 0 ? (
            <ul className="border-2 lista-busca">
              {results.map((result) => (
                <li
                  key={result._id}
                  onClick={() => handleSelectItem(result)}
                  style={{ cursor: "pointer" }}
                >
                  {result.type === "juridica" ? (
                    <i className="bi bi-building-fill mx-2"></i>
                  ) : (
                    <i className="bi bi-person-fill mx-2"></i>
                  )}
                  <b>{result.fullName}</b> ({result.document})
                </li>
              ))}
            </ul>
          ) : null}
        </>
      ) : null}
      {parte ? (
        <div className="d-flex flex-column">
          <input
            className="form-control"
            type="text"
            value={parte.fullName}
            readOnly
            style={{ border: "none", background: "transparent", width: "auto" }}
            hidden
          />
          <div className="d-flex align-items-center btn btn-primary">
            <span className="mx-3">
              {title}: {parte.fullName} ({parte.document})
            </span>
            <div
              className="btn btn-danger"
              style={{ width: "auto" }}
              onClick={cleanParte}
            >
              <i className="bi bi-x-circle"></i>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default partesSearch;
