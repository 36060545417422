import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ImeiViewModal = ({
  isImeiModalOpen,
  setIsImeiModalOpen,
  currentProduto,
  currentImeis
}) => {
  return (
    <Dialog
      open={isImeiModalOpen}
      onClose={() => setIsImeiModalOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {currentProduto.descricao}
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setIsImeiModalOpen(false)}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 18,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          maxHeight: "60vh", // Limita a altura máxima do conteúdo
          overflowY: "auto" // Adiciona barra de rolagem vertical se necessário
        }}
      >
        <p>IMEIs disponíveis em estoque:</p>
        <List>
          {currentImeis.map((imei, index) => (
            <ListItem
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "4px 0" // Diminui o padding do ListItem
              }}
            >
              <ListItemText
                primary={
                  <span style={{ fontSize: "0.875rem" }}>
                    {currentProduto.descricao} - <b>{imei.number}</b>
                  </span>
                }
                sx={{ margin: 0 }} // Remove a margem extra
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setIsImeiModalOpen(false)} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImeiViewModal;
