import React, { useEffect, useState } from "react";
import PartesSearch from "../components/PartesSearch";
import ImeiReader from "../components/ImeiReader";
import api from "../utils/api.utils";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";

import InputMask from "react-input-mask";
import SearchProduto from "../components/SearchProduto";
import ModalAddParte from "../components/ModalAddParte";
import { useAuth } from "../context/AuthContext";

const AddTransaction = ({
  message,
  setMessage,
  openModal,
  showModal,
  closeModal,
}) => {
  const navigate = useNavigate();

  const { userData } = useAuth();

  const [error, setError] = useState(null);

  const [buyDate, setBuyDate] = useState("");
  const [parte, setParte] = useState("");
  const [selectedProduto, setSelectedProduto] = useState("");

  const [imeiArray, setImeiArray] = useState([]);

  const [errorImei, setErrorImei] = useState("");

  const [price, setPrice] = useState(0);
  const [priceDb, setPriceDb] = useState(0);

  const [priceVenda, setPriceVenda] = useState(0);
  const [priceVendaDb, setPriceVendaDb] = useState(0);

  const [priceTotal, setPriceTotal] = useState(0);

  const handleImeiAdd = async (imei, serial) => {
    try {
      await api.buscarImeiDadosCompra(imei);

      // Verifica se o IMEI já existe no imeiArray
      const isImeiAlreadyAdded = imeiArray.some(
        (existingImei) => existingImei.number === imei
      );
      if (!isImeiAlreadyAdded) {
        // Se o IMEI não existe adiciona
        setImeiArray([{ number: imei, serial: serial }, ...imeiArray]);
      } else {
        setErrorImei("IMEI já incluso nessa compra!");
      }
      calculateTotalValue();
    } catch (error) {
      setErrorImei(error);
      console.error(error);
    }
  };

  //remove Imei
  const removeImei = (index) => {
    const updatedImeiArray = [...imeiArray];
    updatedImeiArray.splice(index, 1);
    setImeiArray(updatedImeiArray);
    calculateTotalValue();
  };

  const handleValorChange = (e) => {
    const inputValor = e.target.value;
    const valorNumerico = parseFloat(inputValor.replace(/[^0-9]/g, "")) / 100;

    setPriceDb(valorNumerico);
    setPriceVendaDb(valorNumerico);

    if (!isNaN(valorNumerico)) {
      setPrice(
        valorNumerico.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );

      calculateTotalValue();
    } else {
      setPrice("");
    }
  };

  const handleValorVendaChange = (e) => {
    const inputValor = e.target.value;

    const valorNumericoVenda =
      parseFloat(inputValor.replace(/[^0-9]/g, "")) / 100;

    setPriceVendaDb(valorNumericoVenda);

    if (!isNaN(valorNumericoVenda)) {
      setPriceVenda(
        valorNumericoVenda.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    } else {
      setPrice("");
    }
  };

  const calculateTotalValue = (valorNumerico) => {
    if (!isNaN(valorNumerico)) {
      const totalValue = valorNumerico * imeiArray.length;
      setPriceTotal(totalValue);
    } else {
      setPriceTotal("Valor inválido"); // Ou defina uma mensagem apropriada
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!parte) {
      setError("Necessário informar o Fornecedor");
      return;
    }
    if (imeiArray.length < 1) {
      setError("Necessário informar ao menos 1 IMEI");
      return;
    }
    if (!selectedProduto) {
      setError("Necessário informar o Produto");
      return;
    }

    try {
      const newCompra = await api.addImei({
        buyDate,
        priceDb,
        priceVendaDb,
        valorFormatado,
        parte,
        selectedProduto,
        imeiArray,
      });
      if (newCompra) {
        setBuyDate("");
        setParte("");
        setSelectedProduto("");
        setImeiArray("");
        setPrice("");
        setPriceDb("");
        setMessage("Compra cadastrada com sucesso!");
        navigate("/estoque/");
        setTimeout(() => {
          setMessage("");
        }, 4000);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 10000);
  }, []);

  const formataValor = (valor) => {
    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  let valorFormatado = parseFloat(priceDb * imeiArray.length);

  const [statusModal, setStatusModal] = useState(false);

  const showModalAdd = () => {
    setStatusModal(true);
  };

  return (
    <>
      <div
        className="d-flex flex-column p-3 page"
        style={{ marginTop: "80px", marginBottom: "100px" }}
      >
        <h5 className="mt-3 mb-3">
          <i className="bi bi-cash-coin"></i> Cadastrando Compra
        </h5>
        {error ? (
          <div className="alert alert-danger text-center">
            <b>{error}</b>
          </div>
        ) : null}
        <form className="d-flex flex-column align-items-end">
          <div className="w-100 d-flex justify-content-between align-items-center flex-wrap">
            <div className=" col-12 col-lg-2">
              <div className="">
                <TextField
                  label="Data da compra"
                  type="date"
                  value={buyDate}
                  onChange={(e) => setBuyDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <div className=" col-12 col-lg-6">
              <div className="">
                <PartesSearch
                  title="Fornecedor"
                  parte={parte}
                  setParte={setParte}
                  setError={setError}
                  error={error}
                />
              </div>
            </div>
            <div className=" col-12 col-lg-3">
              <Button variant="contained" onClick={showModalAdd}>
                Cadastrar Fornecedor
              </Button>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-between flex-wrap">
            <div className=" col-12 col-lg-12">
              <div className="form-group">
                <SearchProduto
                  title="Produto"
                  selectedProduto={selectedProduto}
                  setSelectedProduto={setSelectedProduto}
                  setError={setError}
                  error={error}
                  openModal={openModal}
                  showModal={showModal}
                  closeModal={closeModal}
                  userData={userData}
                />
              </div>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-between flex-wrap">
            <div className="col-12 col-md-8">
              {/* Integre o componente ImeiReader e passe a função de callback */}
              <ImeiReader onImeiAdd={handleImeiAdd} />
              {errorImei && (
                <div className="alert alert-danger">{errorImei}</div>
              )}

              <div>
                <label>IMEIs adicionados:</label>
                <ul className="p-0">
                  {imeiArray.map((imei, index) => (
                    <li className="d-flex align-items-center" key={index}>
                      <div
                        className="btn btn-danger"
                        style={{ width: "auto" }}
                        onClick={() => removeImei(index)}
                      >
                        <i className="bi bi-trash"></i>
                      </div>
                      <div className="lista-imeis w-100">
                        <b> IMEI {imeiArray.length - index}:</b> {imei.number}{" "}
                        {imei.serial ? <> - (Serial: {imei.serial})</> : null}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group">
                <label htmlFor="price">Valor de compra (individual):</label>
                <InputMask
                  mask=""
                  maskChar=""
                  alwaysShowMask={false}
                  id="price"
                  name="price"
                  value={price}
                  placeholder="0,00"
                  onChange={handleValorChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="price">Valor de venda (individual):</label>
                <InputMask
                  mask=""
                  maskChar=""
                  alwaysShowMask={false}
                  id="priceVenda"
                  name="priceVenda"
                  value={priceVenda}
                  placeholder="0,00"
                  onChange={handleValorVendaChange}
                  className="form-control"
                />
              </div>
              <div className="alert alert-success">
                <b>
                  Valor total: R$ {priceTotal && formataValor(valorFormatado)}
                </b>
              </div>
            </div>
          </div>
        </form>
        <div className="d-flex flex-column align-items-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Cadastrar
          </button>
        </div>
      </div>
      {/*MODAL ADD PARTE*/}
      <ModalAddParte
        title="fornecedor"
        statusModal={statusModal}
        setStatusModal={setStatusModal}
      />
    </>
  );
};
export default AddTransaction;
