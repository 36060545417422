import React, { useEffect, useState } from "react";
import api from "../utils/api.utils";
import { Link, useNavigate } from "react-router-dom";
import {
  CenterFocusStrong,
  Delete as DeleteIcon,
  MobileOff,
  ModeEdit,
  SystemSecurityUpdateGood
} from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  TextField,
  Tooltip
} from "@mui/material";
import { formatarDataEHora } from "../utils/formatter";
import EditProductModal from "../components/modals/EditProdutos";
import ImeiViewModal from "../components/modals/ViewImeisProdutos";
import ConfirmationModal from "../components/modals/ConfirmEditProdutos";

const Produtos = ({ setError, loading, setLoading, userData }) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingProduto, setDeletingProduto] = useState(null);

  const [loadingEdit, setLoadingEdit] = useState(false);

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingProduto, setEditingProduto] = useState({});

  const navigate = useNavigate();

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProdutos, setFilteredProdutos] = useState([]);

  const [currentImeis, setCurrentImeis] = useState([]);
  const [currentProduto, setCurrentProduto] = useState("");
  const [isImeiModalOpen, setIsImeiModalOpen] = useState(false);

  const getImeis = async (produto) => {
    try {
      const imeisData = await api.getImeisByProdutoId(produto._id);
      setIsImeiModalOpen(true);
      setCurrentImeis(imeisData);
      setCurrentProduto(produto);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let response;
      if (searchTerm.length > 0) {
        response = await api.getProdutosEstoque(
          currentPage,
          itemsPerPage,
          searchTerm
        );
      } else {
        response = await api.getProdutosEstoque(currentPage, itemsPerPage);
      }

      const { produtos, totalPages } = response;
      setTotalPages(totalPages);

      setFilteredProdutos(produtos);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, searchTerm]);

  const handleSearchChange = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.trim() === "") {
      setFilteredProdutos([]); // Limpa os produtos filtrados se não houver busca
      return;
    }
    setLoading(true);
  };

  // Função para abrir o modal de edição
  const handleEdit = async (produtoID) => {
    try {
      // Buscar os detalhes do produto com base no produtoID
      setEditModalOpen(true);
      setLoadingEdit(true);
      const produto = await api.getProdutoByID(produtoID);
      setEditingProduto(produto);
      setLoadingEdit(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Não foi possível carregar os detalhes do produto!");
    }
  };
  // Função para confirmar a edição
  const confirmEdit = async () => {
    try {
      setLoading(true);
      await api.editProduto(editingProduto._id, {
        descricao: editingProduto.descricao,
        marca: editingProduto.marca,
        qtd: editingProduto.qtd,
        hasImei: editingProduto.hasImei,
        valorCompraDb: editingProduto.valorCompraDb,
        valorVendaDb: editingProduto.valorVendaDb
      });
      setLoading(false);
      setEditModalOpen(false);
      // Recarregue os produtos após a edição
      const updatedProdutos = await api.getProdutosEstoque(
        currentPage,
        itemsPerPage
      );
      const { produtos, totalPages } = updatedProdutos;
      setFilteredProdutos(produtos);
      setTotalPages(totalPages);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Não foi possível editar o produto!");
    }
  };

  // Função para cancelar a edição
  const cancelEdit = () => {
    setEditModalOpen(false);
    setEditingProduto({});
  };
  const handleDelete = async (produto) => {
    setDeletingProduto(produto);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      setLoading(true);
      const deletedProduto = await api.deleteProduto(deletingProduto._id);
      if (deletedProduto) {
        setLoading(false);
        setDeleteModalOpen(false);
        navigate(0);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Não foi possível deletar o produto!");
    }
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setDeletingProduto(null);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const renderPaginationControls = () => (
    <div className="d-flex justify-content-center mt-3">
      <button
        className="btn btn-secondary mx-2"
        onClick={handlePrevPage}
        disabled={currentPage === 1}
      >
        Página Anterior
      </button>
      <span>
        Página {currentPage} de {totalPages}
      </span>
      <button
        className="btn btn-secondary mx-2"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        Próxima Página
      </button>
    </div>
  );

  const renderTable = () => {
    if (loading) {
      return (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      );
    }

    if (filteredProdutos.length === 0) {
      return (
        <div className="text-center text-dark alert alert-warning mt-3">
          Nenhum produto em estoque!
        </div>
      );
    }
    return (
      <table className="table mb-0 table-striped table-hover">
        <thead>
          <tr>
            <th>Cadastro</th>
            <th>IMEI?</th>
            <th>Marca</th>
            <th>Descrição</th>
            <th>Quantidade</th>
            <th style={{ width: "15%" }}></th>
          </tr>
        </thead>
        <tbody>
          {filteredProdutos.map((produto, index) => {
            return (
              <tr key={index}>
                <td>{formatarDataEHora(produto.createdAt)}</td>
                <td>
                  {produto.hasImei === false && <MobileOff />}
                  {produto.hasImei === true && <SystemSecurityUpdateGood />}
                </td>
                <td>{produto.marca}</td>
                <td>{produto.descricao}</td>
                <td>{produto.count || produto.qtd}</td>
                <td style={{ textAlign: "right", fontSize: "0.8rem" }}>
                  <div className="d-flex">
                    {/*COM IMEIS*/}
                    {produto.hasImei && (
                      <Tooltip title="Visualizar IMEI's">
                        <IconButton
                          color="success"
                          onClick={() => getImeis(produto)}
                        >
                          <CenterFocusStrong />
                        </IconButton>
                      </Tooltip>
                    )}

                    {/*TODOS*/}
                    {userData.admin && (
                      <Tooltip
                        title="Editar dados do produto"
                        disableInteractive
                      >
                        <IconButton
                          color="warning"
                          onClick={() => handleEdit(produto._id)}
                        >
                          <ModeEdit />
                        </IconButton>
                      </Tooltip>
                    )}
                    {/*QTD ZERO*/}
                    {produto.qtd === 0 && userData.admin && (
                      <Tooltip title="Deletar produto" disableInteractive>
                        <IconButton
                          color="error"
                          onClick={() => handleDelete(produto)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  return (
    <div
      className="p-3 d-flex flex-column page"
      style={{ marginTop: "70px", marginBottom: "100px" }}
    >
      <div className="d-flex justify-content-between">
        <h3>Produtos</h3>
        <Link className="btn btn-success mb-3" to="/produtos/cadastrando">
          Cadastrar Produto
        </Link>
      </div>
      <TextField
        label="Pesquisar produtos"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        margin="normal"
      />
      {renderTable()}
      {renderPaginationControls()}
      {/* Modal de Confirmação */}
      <ConfirmationModal
        isDeleteModalOpen={isDeleteModalOpen}
        confirmDelete={confirmDelete}
        cancelDelete={cancelDelete}
        deletingProduto={deletingProduto}
      />
      {/* Modal de Edit */}
      <EditProductModal
        isEditModalOpen={isEditModalOpen}
        loadingEdit={loadingEdit}
        editingProduto={editingProduto}
        setEditingProduto={setEditingProduto}
        cancelEdit={cancelEdit}
        confirmEdit={confirmEdit}
      />
      {/* Modal DE VIEW IMEIS */}
      <ImeiViewModal
        isImeiModalOpen={isImeiModalOpen}
        setIsImeiModalOpen={setIsImeiModalOpen}
        currentProduto={currentProduto}
        currentImeis={currentImeis}
      />
    </div>
  );
};

export default Produtos;
