import React, { useState } from "react";
import InputMask from "react-input-mask";
import api from "../utils/api.utils";
import PartesSearchSugestao from "./ParteSearchSugestao";

export const PartesAdd = ({
  show,
  onClose,
  message,
  setMessage,
  error,
  setError,
  userId,
  updateParteList,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [document, setDocument] = useState("");
  const [type, setType] = useState("fisica");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const parteData = {
      name,
      email,
      phone,
      document,
      type,
    };
    if (parteData) {
      try {
        const newParte = await api.addParte({ parteData, userId });

        setName("");
        setEmail("");
        setDocument("");
        setPhone("");
        setType("fisica");

        onClose();
        updateParteList(newParte);
        setMessage("Parte cadastrada com sucesso!");
        setTimeout(() => {
          setMessage("");
        }, 5000);
      } catch (error) {
        setError(error);
      }
    }
  };

  return (
    <div className={`modal2 modal-lg ${show ? "show" : ""}`}>
      <div
        className="modal-dialog text-dark"
        style={{ top: "50%", transform: "translateY(-50%)" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Cadastro de Clientes & Fornecedores</h5>
            <div>
              <div
                className="d-flex align-items-center btn btn-outline-info  close"
                onClick={onClose}
              >
                <span>&times;</span>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <form>
              {error ? <div className="alert alert-danger">{error}</div> : null}
              <div className="form-group">
                <label>Tipo de Pessoa:</label>
                <select
                  name="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  className="form-control"
                >
                  <option value="fisica">Pessoa Física</option>
                  <option value="juridica">Pessoa Jurídica</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="name">
                  {type === "fisica" ? (
                    <span>Nome Completo</span>
                  ) : (
                    <span>Razão Social/Nome fantasia</span>
                  )}
                  :
                </label>
                {/* <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                /> */}
                <PartesSearchSugestao
                  searchTerm={name}
                  setSearchTerm={setName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="document">
                  {type === "fisica" ? <span>CPF</span> : <span>CNPJ</span>}:
                </label>
                {type === "fisica" ? (
                  <InputMask
                    className="form-control mb-3"
                    value={document}
                    name="document"
                    type="text"
                    mask="999.999.999-99"
                    onChange={(e) => setDocument(e.target.value)}
                  />
                ) : (
                  <InputMask
                    className="form-control mb-3"
                    value={document}
                    name="document"
                    type="text"
                    mask="99.999.999/9999-99"
                    onChange={(e) => setDocument(e.target.value)}
                  />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Telefone:</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Cadastrar
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
