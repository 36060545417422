import "./App.css";
import React, { useState } from "react";

import RoutesComponents from "./utils/RoutesComponents.jsx";
import { AuthProvider, useAuth } from "./context/AuthContext.jsx";

function App() {
  const { userData, logout } = useAuth() || {};

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <AuthProvider>
      <RoutesComponents
        userData={userData}
        showModal={showModal}
        setShowModal={setShowModal}
        closeModal={closeModal}
        openModal={openModal}
        logout={logout}
      />
    </AuthProvider>
  );
}

export default App;
