import React, { useEffect, useState } from "react";
import api from "../utils/api.utils";
import { Link, useNavigate } from "react-router-dom";
import ModalDeleteImei from "../components/ModalDeleteImei";
import { CircularProgress } from "@mui/material";
import { formatarValor } from "../utils/formatter";

const Dispositivos = ({ error, setError, loading, setLoading, userData }) => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");

  const [fullEstoque, setFullEstoque] = useState("");
  const [valorTotalEstoque, setValorTotalEstoque] = useState("");

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingCompra, setDeletingCompra] = useState(null);
  const [currentIMEI, setCurrentIMEI] = useState("");

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEstoque, setFilteredEstoque] = useState([]);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const renderPaginationControls = () => (
    <div className="d-flex justify-content-center mt-3">
      <button
        className="btn btn-secondary mx-2"
        onClick={handlePrevPage}
        disabled={currentPage === 1}
      >
        Página Anterior
      </button>
      <span>
        Página {currentPage} de {totalPages}
      </span>
      <button
        className="btn btn-secondary mx-2"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        Próxima Página
      </button>
    </div>
  );

  const getEstoque = async () => {
    try {
      setLoading(true);
      let response;

      if (searchTerm.length > 0) {
        response = await api.getEstoque(currentPage, itemsPerPage, searchTerm);
      } else {
        response = await api.getEstoque(currentPage, itemsPerPage);
      }
      const { produtos, totalPages, valorTotalVenda } = response;
      setTotalPages(totalPages);
      setValorTotalEstoque(valorTotalVenda);
      setFilteredEstoque(produtos);
      setLoading(false);
    } catch (error) {
      setMessage("Error");
      console.error(error);
    }
  };

  useEffect(() => {
    const getFullEstoque = async () => {
      try {
        const response = await api.getCountEstoque();
        setFullEstoque(response.quantidade);
      } catch (error) {
        console.log(error);
      }
    };
    getFullEstoque();
  }, []);

  useEffect(() => {
    getEstoque();
  }, [currentPage, searchTerm]);

  // let valorEstoque = 0;
  // for (let index = 0; index < fullEstoque.length; index++) {
  //   if (
  //     fullEstoque[index].transactions &&
  //     fullEstoque[index].transactions.length > 0
  //   ) {
  //     const element = fullEstoque[index].transactions[0].produtos[0];

  //     if (element && typeof element.valorVenda === "number") {
  //       valorEstoque += element.valorVenda;
  //     }
  //   }
  // }

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    setCurrentPage(1);

    if (term.trim() === "") {
      setFilteredEstoque([]); // Limpa os produtos filtrados se não houver busca
      return;
    }
    setLoading(true);
  };

  const renderTable = () => {
    if (loading) {
      return (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      );
    }

    if (filteredEstoque && filteredEstoque.length === 0) {
      return (
        <div className="text-center text-dark alert alert-warning mt-3">
          Nenhum produto em estoque!
        </div>
      );
    }

    return (
      <table className="table mb-0 table-striped table-hover mb-3">
        <thead>
          <tr>
            <th>Data</th>
            <th>Transação</th>
            <th>IMEI's</th>
            <th>Preço</th>
            <th style={{ width: "15%" }}></th>
          </tr>
        </thead>
        <tbody>
          {filteredEstoque.map((imei, index) => {
            const hasTransactions =
              imei.transactions && imei.transactions.length > 0;

            // Pega a última transação (se houver)
            const lastTransaction = hasTransactions
              ? imei.transactions[imei.transactions.length - 1]
              : null;
            let produtoValorVenda = lastTransaction.produtos.find(
              (produto) => produto.imeiId === imei._id
            );
            let valorVenda = produtoValorVenda
              ? produtoValorVenda.valorVenda
              : 0;

            return (
              <tr key={index}>
                <td>
                  {lastTransaction &&
                    new Date(
                      lastTransaction.date || lastTransaction.createdAt
                    ).toLocaleDateString("pt-br", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    })}
                </td>
                <td>
                  <strong>
                    {lastTransaction.type.slice(0, 3)}
                    {lastTransaction && lastTransaction.number}
                  </strong>
                </td>
                <td>
                  {imei.produtoId && imei.produtoId.descricao} (IMEI:{" "}
                  {imei.number})
                </td>
                <td>R$ {formatarValor(valorVenda && valorVenda)}</td>
                <td>
                  {userData.admin ? (
                    <div
                      className="btn btn-danger"
                      onClick={() => {
                        handleDelete(lastTransaction, imei);
                      }}
                    >
                      <i className="bi bi-trash3-fill"></i>
                    </div>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const handleDelete = async (lastTransaction, imei) => {
    setDeletingCompra(lastTransaction);
    setCurrentIMEI(imei);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      setLoading(true);
      const deletedProduto = await api.deleteProdutoIMEI(currentIMEI._id, {
        deletingCompra,
        currentIMEI,
      });
      if (deletedProduto) {
        setLoading(false);
        setDeleteModalOpen(false);
        navigate(0);
      }
    } catch (error) {
      setLoading(false);
      setDeleteModalOpen(false);
      setError(error);
    }
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setDeletingCompra(null);
  };

  return (
    <div
      className=" d-flex flex-column page"
      style={{
        marginTop: "70px",
        marginBottom: "120px",

        padding: "1rem 1rem 1rem 2rem",
      }}
    >
      <div className="d-flex align-items-baseline justify-content-between mb-3">
        <h3>
          <i className="bi bi-phone-vibrate mx-3"></i>Dispositivos em estoque
        </h3>
        <div className="mb-3">
          <div className="d-flex align-items-center alert alert-info">
            <span>
              Quantidade: <b> {fullEstoque}</b>
            </span>
            {userData.admin ? (
              <>
                <span className="mx-3"> | </span>
                <span>Total: R$ {formatarValor(valorTotalEstoque)}</span>
              </>
            ) : null}
          </div>

          <Link
            className="btn btn-success"
            to={"/cadastrando/transaction/compras/"}
          >
            Cadastrar IMEIs
          </Link>
        </div>
      </div>
      <div className="mb-3">
        <input
          className="form-control"
          type="text"
          placeholder="Pesquisar por IMEI"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {filteredEstoque.length > 0 ? (
        <span>{filteredEstoque.length} itens no filtro.</span>
      ) : null}{" "}
      <hr />
      {message ? <div className="alert alert-success">{message}</div> : null}
      {error ? <div className="alert alert-danger">{error}</div> : null}
      <div className="border p-2 mb-3 shadow rounded w-100">
        {renderTable()}
      </div>
      {renderPaginationControls()}
      <ModalDeleteImei
        isDeleteModalOpen={isDeleteModalOpen}
        cancelDelete={cancelDelete}
        lastTransaction={deletingCompra}
        currentIMEI={currentIMEI}
        error={error}
        setError={setError}
        userData={userData}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default Dispositivos;
