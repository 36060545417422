import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from "@mui/material";

const ConfirmationModal = ({
  isDeleteModalOpen,
  deletingProduto,
  confirmDelete,
  cancelDelete
}) => {
  return (
    deletingProduto && (
      <Dialog
        open={isDeleteModalOpen}
        onClose={cancelDelete}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <p>Tem certeza que deseja excluir o produto:</p>
          <p>
            <b>{deletingProduto.descricao}</b> da marca{" "}
            <b>{deletingProduto.marca}</b>?
          </p>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancelDelete} color="secondary">
            Cancelar
          </Button>
          <Button variant="contained" onClick={confirmDelete} color="error">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default ConfirmationModal;
