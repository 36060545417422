import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { Auditoria } from "../views/Auditoria";

import MeuCaixa from "../views/MeuCaixa";
import { Partes } from "../views/Partes.js";

import ViewUser from "../views/ViewUser";
import TodosCaixas from "../views/TodosCaixas";
import FechandoCaixa from "../views/FechandoCaixa";
import Dispositivos from "../views/Dispositivos.js";
import AddProduto from "../views/AddProduto.js";
import Produtos from "../views/Produtos.js";
import Relatorio from "../views/Relatorio.js";
import AddTransaction from "../views/AddTransaction.js";
import AddTransactionVenda from "../views/AddTransactionsVenda.js";
import Transactions from "../views/Transactions.js";
import AddDevolucao from "../views/AddDevolucao.js";
import EditTransaction from "../views/EditTransaction.js";
import User from "../views/User";
import Logout from "../views/Logout.js";
import { LoginPage } from "../views/LoginPage";
import RedefinirSenha from "../views/RedefinirSenha.js";

import AppNavbar from "../components/AppNavbar";
import SideBar from "../components/Sidebar.js";
import { Footer } from "../components/Footer.js";

import {
  formatarData,
  formatarDataEHora,
  formatarValor,
} from "./formatter.jsx";

import { useAuth } from "../context/AuthContext.jsx";

export default function RoutesComponents({
  showModal,
  setShowModal,
  closeModal,
  openModal,
}) {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const { userData, isLogged, setIsLogged, logout } = useAuth();

  useEffect(() => {
    setInterval(() => {
      setMessage("");
    }, 10000);
  });

  return (
    <>
      {isLogged && (
        <>
          <AppNavbar
            onLogout={logout}
            isLogged={isLogged}
            setIsLogged={setIsLogged}
            userData={userData}
            setError={setError}
          />
          <SideBar logout={logout} />
        </>
      )}

      <Routes>
        <Route
          path="/"
          element={
            <LoginPage
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
            />
          }
        />

        <Route
          path="/redefinir-senha/:id"
          element={<RedefinirSenha setMessage={setMessage} />}
        />

        <Route
          path="/logout/"
          element={
            <Logout
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
            />
          }
        />
        <Route
          path="/clientes-fornecedores/"
          element={
            <Partes
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              showModal={showModal}
              setShowModal={setShowModal}
              openModal={openModal}
              closeModal={closeModal}
              userData={userData}
            />
          }
        />
        <Route
          path="/produtos/"
          element={
            <Produtos
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              userData={userData}
            />
          }
        />
        <Route
          path="/produtos/cadastrando"
          element={
            <AddProduto
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              showModal={showModal}
              setShowModal={setShowModal}
              openModal={openModal}
              closeModal={closeModal}
              userData={userData}
            />
          }
        />
        <Route
          path="/cadastrando/transaction/compras/"
          element={
            <AddTransaction
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              formatarData={formatarData}
              formatarDataEHora={formatarDataEHora}
              userData={userData}
            />
          }
        />
        <Route
          path="/cadastrando/transaction/vendas/"
          element={
            <AddTransactionVenda
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              formatarData={formatarData}
              formatarDataEHora={formatarDataEHora}
              userData={userData}
            />
          }
        />
        <Route
          path="/editando/transaction/:type/:id"
          element={
            <EditTransaction
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              formatarData={formatarData}
              formatarDataEHora={formatarDataEHora}
              userData={userData}
            />
          }
        />
        <Route
          path="/estoque/"
          element={
            <Dispositivos
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              showModal={showModal}
              setShowModal={setShowModal}
              openModal={openModal}
              closeModal={closeModal}
              userData={userData}
            />
          }
        />
        <Route
          path="/:type/transactions/"
          element={
            <Transactions
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              showModal={showModal}
              setShowModal={setShowModal}
              openModal={openModal}
              closeModal={closeModal}
              userData={userData}
              formatarValor={formatarValor}
              formatarDataEHora={formatarDataEHora}
            />
          }
        />
        <Route
          path="/cadastrando/transaction/devolucao/"
          element={
            <AddDevolucao
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              showModal={showModal}
              setShowModal={setShowModal}
              openModal={openModal}
              closeModal={closeModal}
              userData={userData}
              formatarValor={formatarValor}
              formatarData={formatarData}
            />
          }
        />
        <Route
          path="/meu-caixa/"
          element={
            <MeuCaixa
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              formatarData={formatarData}
              formatarDataEHora={formatarDataEHora}
              userData={userData}
              formatarValor={formatarValor}
            />
          }
        />
        <Route
          path="/auditoria/"
          element={
            <Auditoria
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              formatarData={formatarData}
              formatarDataEHora={formatarDataEHora}
            />
          }
        />
        <Route
          path="/usuarios/"
          element={
            <User
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              formatarData={formatarData}
              formatarDataEHora={formatarDataEHora}
            />
          }
        />
        <Route
          path="/relatorios"
          element={
            <Relatorio
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              formatarData={formatarData}
              formatarValor={formatarValor}
              formatarDataEHora={formatarDataEHora}
              openModal={openModal}
              closeModal={closeModal}
              showModal={showModal}
            />
          }
        />
        <Route
          path="/usuarios/:id"
          element={
            <ViewUser
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
            />
          }
        />
        <Route
          path="/todos-caixas/"
          element={
            <TodosCaixas
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              userData={userData}
              showModal={showModal}
              setShowModal={setShowModal}
              formatarData={formatarData}
              formatarDataEHora={formatarDataEHora}
            />
          }
        />
        <Route
          path="/caixa/fechando/:caixa_id"
          element={
            <FechandoCaixa
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
              showModal={showModal}
              setShowModal={setShowModal}
              openModal={openModal}
              closeModal={closeModal}
              formatarData={formatarData}
              formatarDataEHora={formatarDataEHora}
            />
          }
        />
      </Routes>
      {isLogged ? <Footer /> : null}
    </>
  );
}
