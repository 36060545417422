import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField
} from "@mui/material";

const EditProductModal = ({
  isEditModalOpen,
  loadingEdit,
  editingProduto,
  setEditingProduto,
  cancelEdit,
  confirmEdit
}) => {
  const handleChange = (event, field) => {
    setEditingProduto({ ...editingProduto, [field]: event.target.value });
  };

  return (
    <Dialog open={isEditModalOpen} onClose={cancelEdit} maxWidth="md" fullWidth>
      <DialogTitle>Editar Produto</DialogTitle>
      <DialogContent>
        {!loadingEdit && editingProduto ? (
          <form>
            <TextField
              label="Nome"
              fullWidth
              variant="outlined"
              margin="normal"
              value={editingProduto.descricao || ""}
              onChange={(e) => handleChange(e, "descricao")}
            />
            <TextField
              label="Marca"
              fullWidth
              variant="outlined"
              margin="normal"
              value={editingProduto.marca || ""}
              onChange={(e) => handleChange(e, "marca")}
            />
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="imei-label">Tem IMEI?</InputLabel>
              <Select
                labelId="imei-label"
                value={editingProduto.hasImei || ""}
                onChange={(e) => handleChange(e, "hasImei")}
                label="Tem IMEI?"
                sx={{
                  backgroundColor: "white"
                }}
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                <MenuItem value="true">Sim</MenuItem>
                <MenuItem value="false">Não</MenuItem>
              </Select>
              {editingProduto.hasImei === "" && (
                <FormHelperText>
                  Selecione se o produto tem IMEI ou não
                </FormHelperText>
              )}
            </FormControl>

            {editingProduto.hasImei === "false" && (
              <>
                <TextField
                  label="Quantidade"
                  fullWidth
                  type="number"
                  variant="outlined"
                  margin="normal"
                  value={editingProduto.qtd || 0}
                  onChange={(e) => handleChange(e, "qtd")}
                />
                <TextField
                  label="Valor de Compra"
                  fullWidth
                  type="number"
                  variant="outlined"
                  margin="normal"
                  value={editingProduto.valorCompraDb || 0}
                  onChange={(e) => handleChange(e, "valorCompraDb")}
                />
                <TextField
                  label="Valor de Venda"
                  fullWidth
                  type="number"
                  variant="outlined"
                  margin="normal"
                  value={editingProduto.valorVendaDb || 0}
                  onChange={(e) => handleChange(e, "valorVendaDb")}
                />
              </>
            )}
          </form>
        ) : (
          <div className="d-flex justify-content-center">
            <CircularProgress />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={cancelEdit} color="secondary">
          Cancelar
        </Button>
        <Button variant="contained" onClick={confirmEdit} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProductModal;
