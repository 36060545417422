import React, { useState } from "react";
import InputMask from "react-input-mask";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import api from "../utils/api.utils";
import { useAuth } from "../context/AuthContext";

function ModalAddParte({ title, statusModal, setStatusModal }) {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const { userData } = useAuth();

  const [parteData, setPartesData] = useState({
    name: "",
    email: "",
    phone: "",
    document: "",
    type: "fisica"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPartesData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { userId } = userData;

    if (parteData) {
      try {
        await api.addParte({ parteData, userId });

        // Em seguida, limpe o formulário e feche o modal.
        setPartesData({
          name: "",
          email: "",
          phone: "",
          document: "",
          type: "fisica"
        });
        setStatusModal(false);
        setMessage("Parte cadastrada com sucesso!");
        setTimeout(() => {
          setMessage("");
        }, 5000);
      } catch (error) {
        setError(error);
      }
    }
  };

  return (
    <Dialog
      fullWidth
      open={Boolean(statusModal)}
      onClose={() => setStatusModal(false)}
    >
      <DialogTitle>
        Adicionando {title}
        <IconButton
          aria-label="close"
          style={{ position: "absolute", right: 8, top: 8 }}
          onClick={() => setStatusModal(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          {error ? <div className="alert alert-danger">{error}</div> : null}
          {message ? <div className="alert alert-info">{message}</div> : null}
          <div className="form-group">
            <label>Tipo de Pessoa:</label>
            <select
              name="type"
              value={parteData.type}
              onChange={handleChange}
              className="form-control"
            >
              <option value="fisica">Pessoa Física</option>
              <option value="juridica">Pessoa Jurídica</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="name">
              {parteData.type === "fisica" ? (
                <span>Nome Completo</span>
              ) : (
                <span>Razão Social/Nome fantasia</span>
              )}
              :
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={parteData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="document">
              {parteData.type === "fisica" ? (
                <span>CPF</span>
              ) : (
                <span>CNPJ</span>
              )}
              :
            </label>
            {parteData.type === "fisica" ? (
              <InputMask
                className="form-control mb-3"
                value={parteData.document}
                name="document"
                type="text"
                mask="999.999.999-99"
                onChange={handleChange}
              />
            ) : (
              <InputMask
                className="form-control mb-3"
                value={parteData.document}
                name="document"
                type="text"
                mask="99.999.999/9999-99"
                onChange={handleChange}
              />
            )}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={parteData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Telefone:</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              value={parteData.phone}
              onChange={handleChange}
            />
          </div>
          <Button variant="contained" onClick={handleSubmit}>
            Cadastrar
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ModalAddParte;
