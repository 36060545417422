import React, { useEffect, useState } from "react";
import api from "../utils/api.utils";
import { PartesAdd } from "../components/PartesAdd";
import ParteItem from "../components/PartesItem";
import PagControls from "../components/PagControls";
import FilterAlphabet from "../components/FilterAlphabet";
import { ModalEditParte } from "../components/ModalEditParte";
import { ModalDeleteParte } from "../components/ModalDeleteParte";

export const Partes = ({
  message,
  setMessage,
  error,
  setError,
  loading,
  setLoading,
  userId,
  openModal,
  showModal,
  closeModal,
  userData
}) => {
  const [allPartes, setAllPartes] = useState([]);

  const [currentParte, setCurrentParte] = useState("");
  const [editingParte, setEditingParte] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleMsgConfirm = (parte) => {
    setCurrentParte(parte);
    setDeleteModalOpen(true);
  };
  // Função para abrir o modal de edição
  const handleEdit = (parte) => {
    const { _id, fullName, type, document, email, contact, createdAt } = parte;
    try {
      setEditingParte({
        _id,
        fullName,
        type,
        document,
        email,
        contact,
        createdAt
      });
      setEditModalOpen(true);
    } catch (error) {
      console.error(error);
      setError("Não foi possível carregar os detalhes da compra!");
    }
  };
  // Função para confirmar a edição
  const confirmEdit = async () => {
    try {
      setLoading(true);
      await api.editParte({ editingParte });
      setLoading(false);
      setEditModalOpen(false);
      // Recarregue os produtos após a edição
      const updatedCompras = await api.getAllPartes();
      setAllPartes(updatedCompras);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Não foi possível editar a compra!");
    }
  };

  // Função para cancelar a edição
  const cancelEdit = () => {
    setEditModalOpen(false);
    setEditingParte("");
  };

  const deleteParte = async (parteId) => {
    try {
      setLoading(true);
      const deleteParte = await api.deleteParte({ parteId });
      setLoading(false);
      setMessage(deleteParte.msg);
      setDeleteModalOpen(false);
      // Remove the deleted venda from the list
      setAllPartes((prevPartes) =>
        prevPartes.filter((parte) => parte._id !== parteId)
      );
      setTimeout(() => {
        setMessage("");
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  };

  const closeDelete = () => {
    setDeleteModalOpen(false);
  };

  const updateParteList = (newParte) => {
    setAllPartes([...allPartes, newParte]);
  };

  useEffect(() => {
    const getPartes = async () => {
      try {
        const data = await api.getAllPartes();
        setAllPartes(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getPartes();
  }, [loading, setLoading]);

  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    const sorted = [...allPartes].sort((a, b) =>
      a.fullName.localeCompare(b.fullName)
    );
    setSortedData(sorted);
  }, [allPartes]);

  const itemsPerPage = 10;
  const [selectedAlphabet, setSelectedAlphabet] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAlphabetFilter = (alphabet) => {
    setSelectedAlphabet(alphabet);
    setCurrentPage(1);
  };

  const filteredData = selectedAlphabet
    ? sortedData.filter((item) => item.fullName.startsWith(selectedAlphabet))
    : sortedData;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const renderData = currentData.map((item) => (
    <ParteItem
      key={item._id}
      item={item}
      userData={userData}
      handleEdit={handleEdit}
      handleMsgConfirm={handleMsgConfirm}
    />
  ));

  const renderPagControle = (
    <PagControls
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
    />
  );

  const alphabetList = [
    ...Array.from({ length: 26 }, (_, index) => String.fromCharCode(65 + index))
  ];

  // Função de clique para o caractere "#"
  const handleClickHash = () => {
    setSelectedAlphabet(""); // Limpa qualquer filtro alfabético
    setCurrentPage(1);
  };

  const renderAlphabetFilter = (
    <FilterAlphabet
      alphabetList={alphabetList}
      selectedAlphabet={selectedAlphabet}
      onAlphabetChange={handleAlphabetFilter}
      handleClickHash={handleClickHash}
    />
  );

  return (
    <div
      className="p-3 d-flex flex-column page"
      style={{ marginTop: "70px", marginBottom: "100px" }}
    >
      <div className="d-flex align-items-baseline justify-content-between">
        <h3>
          <i className="bi bi-person-lines-fill mx-3"></i>Clientes &
          Fornecedores
        </h3>
        <div className="mb-3">
          <div
            className="d-flex align-items-center btn btn-outline-info"
            onClick={openModal}
          >
            <span>Adicionar</span>
            <i className="bi bi-plus-circle-fill mx-1 fs-6"></i>
          </div>
        </div>
      </div>
      <hr />
      {message ? <div className="alert alert-success">{message}</div> : null}
      <div className="border p-2 shadow rounded">
        {renderAlphabetFilter}
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th className="text-center">PF/PJ</th>
              <th>Nome Completo/Razão Social</th>
              <th>CPF/CNPJ</th>
              <th>Contato</th>
              <th>Desde</th>
              <th>Carteira</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderData}</tbody>
        </table>
      </div>
      {allPartes.length > 0 ? (
        renderData.length < 1 && totalPages < 1 ? (
          <div className="text-center text-dark alert alert-warning mt-3">
            Acabou a lista com inicial <strong>{selectedAlphabet}</strong>
          </div>
        ) : (
          renderPagControle
        )
      ) : (
        <div className="text-center text-dark alert alert-warning mt-3">
          Nenhum fornecedor cadastrado.
        </div>
      )}
      {/* Modal de cadastro de parte */}
      <PartesAdd
        show={showModal}
        onClose={closeModal}
        message={message}
        setMessage={setMessage}
        error={error}
        setError={setError}
        userId={userId}
        userData={userData}
        updateParteList={updateParteList}
      />
      <ModalEditParte
        editModalOpen={editModalOpen}
        onClose={closeModal}
        message={message}
        setMessage={setMessage}
        error={error}
        setError={setError}
        userId={userId}
        updateParteList={updateParteList}
        editingParte={editingParte}
        setEditingParte={setEditingParte}
        cancelEdit={cancelEdit}
        confirmEdit={confirmEdit}
      />
      <ModalDeleteParte
        showModalDelete={deleteModalOpen}
        onClose={closeDelete}
        error={error}
        setError={setError}
        userData={userData}
        currentParte={currentParte}
        deleteParte={deleteParte}
      />
    </div>
  );
};
