import React, { useState, useEffect } from "react";
import api from "../utils/api.utils";
import { TextField } from "@mui/material";

const PartesSearchSugestao = ({searchTerm, setSearchTerm}) => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const data = await api.buscaParte(searchTerm);
        setResults(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (searchTerm) {
      fetchSuggestions();
    } else {
      setResults([]);
    }
  }, [searchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <TextField
        label={`Buscar por nome`}
        value={searchTerm}
        onChange={handleInputChange}
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
      {results.length > 0 ? (
        <ul className="border-2 lista-busca">
          {results.map((result) => (
            <li key={result._id} style={{ cursor: "pointer" }}>
              {result.type === "juridica" ? (
                <i className="bi bi-building-fill mx-2"></i>
              ) : (
                <i className="bi bi-person-fill mx-2"></i>
              )}
              <b>{result.fullName}</b> ({result.document})
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default PartesSearchSugestao;
