import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import api from "../utils/api.utils";
import { ViewTransaction } from "../components/ViewTransaction";
import { CircularProgress } from "@mui/material";

const Transactions = ({
  loading,
  setLoading,
  formatarDataEHora,
  formatarValor,
  setError,
  error,
}) => {
  const { type } = useParams();

  // const [transactions, setTransactions] = useState([]);
  const [totalPages, setTotalPages] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const [showModalView, setShowModalView] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const getTransactions = async () => {
      try {
        setLoading(true);

        // Checa se há um termo de pesquisa
        const response = searchTerm
          ? await api.searchTransactions(searchTerm, type)
          : await api.getAllTransactions(currentPage, itemsPerPage, type);

        const { data, totalPages } = response;
        setTotalPages(totalPages);
        setFilteredTransactions(data);
        // setTransactions(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getTransactions();
  }, [currentPage, itemsPerPage, type, searchTerm]);

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    // Reseta para a primeira página ao fazer uma nova busca
    setCurrentPage(1);
  };

  const viewCaixa = (transaction) => {
    setWidth("w-50");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentTransaction(transaction);
    setShowModalView(true);
  };

  const cancelView = () => {
    setWidth("w-100");
    setShowModalView(false);
    setCurrentTransaction(null);
  };

  const [width, setWidth] = useState("w-100");

  useEffect(() => {
    setWidth("w-100");
    setShowModalView(false);
  }, [type]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPagination = () => {
    if (filteredTransactions.length < 10) return null;
    // if (totalPages <= 1) return null;

    const pageNumbers = [];
    const maxPagesToShow = 5;

    const createPageArray = (from, to) => {
      const result = [];
      for (let i = from; i <= to; i++) {
        result.push(i);
      }
      return result;
    };

    if (totalPages <= maxPagesToShow) {
      pageNumbers.push(...createPageArray(1, totalPages));
    } else {
      const leftPages = Math.max(
        1,
        currentPage - Math.floor(maxPagesToShow / 2)
      );
      const rightPages = Math.min(
        totalPages,
        currentPage + Math.floor(maxPagesToShow / 2)
      );

      if (leftPages > 1) pageNumbers.push(1);
      if (leftPages > 2) pageNumbers.push("...");
      pageNumbers.push(...createPageArray(leftPages, rightPages));
      if (rightPages < totalPages - 1) pageNumbers.push("...");
      if (rightPages < totalPages) pageNumbers.push(totalPages);
    }

    return (
      <nav className="d-flex justify-content-center">
        <ul className="pagination d-flex flex-wrap justify-content-center">
          {currentPage > 1 && (
            <li className="page-item">
              <button
                onClick={() => paginate(currentPage - 1)}
                className="page-link"
              >
                &laquo;
              </button>
            </li>
          )}
          {pageNumbers.map((number, index) =>
            number === "..." ? (
              <li key={index} className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            ) : (
              <li
                key={index}
                className={`page-item clickable ${
                  number === currentPage ? "active" : ""
                }`}
              >
                <Link
                  to={""}
                  onClick={() => paginate(number)}
                  className="page-link"
                >
                  {number}
                </Link>
              </li>
            )
          )}

          {currentPage < totalPages && (
            <li className="page-item">
              <button
                onClick={() => paginate(currentPage + 1)}
                className="page-link"
              >
                &raquo;
              </button>
            </li>
          )}
        </ul>
      </nav>
    );
  };

  const renderTransactions = () => {
    const vendasToRender = filteredTransactions;
    if (vendasToRender.length > 0) {
      return (
        <table className="table mb-0 table-striped table-hover">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th style={{ width: "20%" }}>Data</th>
              <th>Valor total</th>
              <th>
                {type !== "compras" ? (
                  <span>Cliente</span>
                ) : (
                  <span>Fornecedor(a)</span>
                )}
              </th>
              <th>
                {type !== "compras" ? (
                  <span>Vendedor(a)</span>
                ) : (
                  <span>Comprador(a)</span>
                )}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {vendasToRender.map((transaction, index) => {
              return (
                <tr key={index}>
                  <td className="text-center">
                    <strong>
                      {transaction.type.slice(0, 3) + transaction.number}
                    </strong>
                  </td>
                  <td>{formatarDataEHora(transaction.createdAt)}h</td>
                  <td>R$ {formatarValor(transaction.valorTotal)}</td>
                  <td>{transaction.parteId && transaction.parteId.fullName}</td>
                  <td>
                    {transaction.transactionUser &&
                      transaction.transactionUser.fullName}
                  </td>
                  <td className="text-center" style={{ width: "5%" }}>
                    {/* {transaction.status === "ATIVO" ? ( */}
                    <div
                      className="btn btn-success"
                      onClick={() => {
                        viewCaixa(transaction);
                      }}
                    >
                      <i className="bi bi-eye-fill"></i>
                    </div>
                    {/* ) : null} */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return (
        <div className="text-center alert alert-info">
          Nenhum resultado para mostrar!
        </div>
      );
    }
  };

  return (
    <div
      className="p-3 d-flex flex-column page"
      style={{ marginTop: "70px", marginBottom: "100px" }}
    >
      <div className="d-flex align-items-baseline justify-content-between">
        <h3>
          <i className="bi bi-cash-coin"></i>{" "}
          {type !== "devolucao" ? (
            type[0].toUpperCase() + type.slice(1)
          ) : (
            <>Devolução</>
          )}
        </h3>
      </div>
      <div className="mb-3">
        <input
          className="form-control"
          type="text"
          placeholder="Pesquisar por número da compra/venda/devolução ou nome do fornecedor/cliente"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {!loading ? (
        <div className="d-flex align-items-start">
          <div
            className={`border p-2 shadow rounded ${width} d-flex flex-column`}
          >
            {renderTransactions()}
          </div>
          <ViewTransaction
            setShowModalView={setShowModalView}
            showModalView={showModalView}
            currentTransaction={currentTransaction}
            formatarValor={formatarValor}
            formatarDataEHora={formatarDataEHora}
            cancelView={cancelView}
            setLoading={setLoading}
            setError={setError}
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      )}
      {renderPagination()}
    </div>
  );
};

export default Transactions;
