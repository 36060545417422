import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { useAuth } from "../context/AuthContext.jsx";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, TextField } from "@mui/material";

export const LoginPage = ({ message, setMessage }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const { login, isLogged, setIsLogged } = useAuth();

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      setMessage("Necessário preencher usuário e senha!");
    } else {
      try {
        setLoading(true);
        await login({ username, password });
        setIsLogged(true);
        setLoading(false);
        setMessage("");
        navigate(`/meu-caixa/`);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setMessage(error || "Erro ao realizar login");
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      navigate(`/meu-caixa/`);
    }
  }, [isLogged, navigate, setIsLogged]);

  return (
    <div className="w-100 login-back d-flex justify-content-center align-items-center vh-100">
      <div className="col-md-12 login-container">
        <form onSubmit={handleLogin} className="container-wrap">
          <h1>
            <i className="bi bi-phone-vibrate"></i> IMEIGyn
          </h1>

          <TextField
            className="w-100 mt-3"
            type="text"
            label="Usuário"
            value={username}
            autoComplete="email"
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            type="password"
            className="w-100 mt-3"
            label="Senha"
            value={password}
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="mt-3 d-flex flex-column mb-3">
            {loading ? (
              <Button variant="contained" disabled>
                <CircularProgress />
              </Button>
            ) : (
              <Button type="submit" variant="contained">
                Entrar
              </Button>
            )}
          </div>

          <div className="d-flex flex-column align-items-center w-100 mt-3">
            {message && (
              <div className="alert alert-success d-flex flex-column align-items-center w-100">
                {message}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
