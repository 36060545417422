/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

const ambiente = [
  "https://imeiapi.iuryflores.dev.br/",
  "http://localhost:9000/"
];

class Api {
  constructor() {
    this.api = axios.create({
      baseURL: ambiente[0]
    });
    this.api.interceptors.request.use(
      (config) => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const token = userData ? userData.token : null;

        if (token) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`
          };
        }
        return config;
      },
      (error) => {
        console.error("Erro na interceptação de requisição:", error);
        return Promise.reject(error);
      }
    );

    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          console.log("deslogou");
          if (!error.config.url.includes("/auth/login")) {
            this.handleUnauthorized();
          }
        }
        return Promise.reject(error);
      }
    );
  }
  clearLocalStorage() {
    localStorage.clear();
  }
  handleUnauthorized() {
    this.clearLocalStorage();
    window.location = "/";
  }
  login = async (loginInfo) => {
    try {
      const { data } = await this.api.post("/user/auth/login", loginInfo);
      return data;
    } catch (error) {
      console.error("Login error: ", error);
      throw error.response.data.msg || "Erro ao fazer login";
    }
  };
  setCookie(name, value, maxAge) {
    document.cookie = `${name}=${value}; Path=/; Max-Age=${maxAge}; Secure; HttpOnly; SameSite=Strict`;
  }
  signup = async (signupInfo) => {
    try {
      const { data } = await this.api.post("/user/auth/signup", signupInfo);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  redefinirSenha = async (id, confirmarSenha) => {
    try {
      const { data } = await this.api.post(
        `/user/auth/redefinir-senha/${id}`,
        confirmarSenha
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getAllPartes = async () => {
    try {
      const { data } = await this.api.get("/partes");
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  addParte = async (parteData, userId) => {
    try {
      const { data } = await this.api.post("/partes/new/", parteData, userId);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  getProdutos = async () => {
    try {
      const { data } = await this.api.get(`/produtos/todos/`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  addProduto = async (produtoData, hasImei, valorCompra, valorVenda) => {
    try {
      const { data } = await this.api.post(
        `/produtos/new/`,
        produtoData,
        hasImei,
        valorCompra,
        valorVenda
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  getProdutoByID = async (produtoID) => {
    try {
      const { data } = await this.api.get(`/produtos/${produtoID}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  buscaParte = async (term) => {
    try {
      const { data } = await this.api.get(`/partes/busca/${term}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  buscaImeiHistoria = async (term) => {
    try {
      const { data } = await this.api.get(
        `/imei/relatorios/historia/imei/${term}`
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  buscaProdutoSemImeiTransaction = async (term) => {
    try {
      const { data } = await this.api.get(`/produtos/busca/sem-imei/${term}/`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  buscaProdutoTransaction = async (term) => {
    try {
      const { data } = await this.api.get(`/produtos/busca/${term}/`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  buscaProduto = async (term, page, limit) => {
    try {
      const { data } = await this.api.get(
        `/produtos/busca/${term}/${page}/${limit}`
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  buscaProdutoEstoque = async (term) => {
    try {
      const { data } = await this.api.get(`/imei/busca/estoque/${term}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  addImei = async (customerData) => {
    try {
      const { data } = await this.api.post(
        "/transactions/new/compra",
        customerData
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getEstoque = async (page, limit, searchTerm) => {
    try {
      const { data } = await this.api.get(
        `/imei/estoque/produtos/${page}/${limit}/${searchTerm}`
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getCountEstoque = async () => {
    try {
      const { data } = await this.api.get("/imei/estoque/count");
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getProdutosEstoque = async (page, limit, searchTerm) => {
    try {
      const { data } = await this.api.get(
        `/imei/produtos-e-estoque/${page}/${limit}/${searchTerm}`
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  deleteProdutoIMEI = async (imei, deletingCompra) => {
    try {
      const { data } = await this.api.put(
        `/imei/${imei}/remove`,
        deletingCompra
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  buscarImeiDadosVenda = async (imeiNumber) => {
    try {
      const { data } = await this.api.get(`/imei/${imeiNumber}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  buscarImeiEditVenda = async (imeiNumber, origemImei) => {
    try {
      const { data } = await this.api.put(
        `/imei/edit/${imeiNumber}`,
        origemImei
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  addVenda = async (formData) => {
    try {
      const { data } = await this.api.post("/transactions/new/venda", formData);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  checkCaixaAberto = async (selectedDate) => {
    try {
      const { data } = await this.api.get(`/caixa/aberto/${selectedDate}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getRelatorio = async (page = 1, limit = 8) => {
    try {
      const { data } = await this.api.get(
        `/imei/relatorios/historia/?page=${page}/&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getTodosCaixas = async () => {
    try {
      const { data } = await this.api.get(`/caixa/todos/`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getVendasByCaixaId = async (caixa_id) => {
    try {
      const { data } = await this.api.get(`/caixa/transactions/${caixa_id}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  editParte = async (parteData) => {
    try {
      const { data } = await this.api.put(`/partes/edit/`, parteData);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  deleteParte = async (parteData) => {
    try {
      const { data } = await this.api.put(`/partes/delete/`, parteData);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getAllTransactions = async (page, limit, type) => {
    try {
      const { data } = await this.api.get(
        `/transactions/?page=${page}&limit=${limit}&type=${type}`
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  searchTransactions = async (searchTerm, type, page, limit) => {
    try {
      const { data } = await this.api.get(
        `/transactions/search/?page=${page}&limit=${limit}&type=${type}&term=${searchTerm}`
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  deleteProdutoDaVenda = async (produtoID, currentTransactionID) => {
    try {
      const { data } = await this.api.delete(
        `/produtos/delete/${produtoID}/venda/${currentTransactionID}`
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  getVendasCliente = async (parteID) => {
    try {
      const { data } = await this.api.get(`/transactions/parte/${parteID}/`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getVendasByID = async (vendaID) => {
    try {
      const { data } = await this.api.get(`/transactions/venda-id/${vendaID}/`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  getTransactionById = async (type, id) => {
    try {
      const { data } = await this.api.get(
        `/transactions/editando/${type}/${id}`
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  editTransaction = async (type, id, formData) => {
    try {
      const { data } = await this.api.put(
        `/transactions/edit/${type}/${id}/editando/`,
        formData
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getCompraByID = async (compraID) => {
    try {
      const { data } = await this.api.get(
        `/transactions/compra-id/${compraID}/`
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  addDevolucao = async (
    parteId,
    produtosSelecionados,
    produtoSelecionado,
    caixaAtual,
    imeiData,
    vendaSelecionada,
    motivo,
    destinoValor,
    userData,
    valorImei,
    destinoEstoque,
    vendaImei
  ) => {
    try {
      const { data } = await this.api.post(
        "/transactions/new/devolucao/",
        parteId,
        produtosSelecionados,
        produtoSelecionado,
        caixaAtual,
        imeiData,
        vendaSelecionada,
        motivo,
        destinoValor,
        userData,
        valorImei,
        destinoEstoque,
        vendaImei
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  addPriceTransaction = async (
    transactionID,
    produtoID,
    produtos,
    valorCompra,
    valorVenda
  ) => {
    try {
      const { data } = await this.api.put(
        `/transactions/add-price/transaction-id/${transactionID}`,
        produtoID,
        produtos,
        valorCompra,
        valorVenda
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  /* ANTIGOS */
  // getAllClients = async () => {
  //   try {
  //     const { data } = await this.api.get("/clientes/");
  //     return data;
  //   } catch (error) {
  //     throw error.response.data.msg;
  //   }
  // };

  buscaCliente = async (term) => {
    try {
      const { data } = await this.api.get(`/clientes/busca/${term}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  buscarImeiDadosCompra = async (imeiNumber) => {
    try {
      const { data } = await this.api.get(`/imei/${imeiNumber}/compra`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  buscarImeiDadosDev = async (imeiNumber) => {
    try {
      const { data } = await this.api.get(`/imei/${imeiNumber}/devolucao`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  devolverImei = async (imeiArray, caixa) => {
    try {
      const { data } = await this.api.post(
        "/imei/devolucao/",
        imeiArray,
        caixa
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  getAllCompras = async () => {
    try {
      const { data } = await this.api.get("/compras/");
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getAllVendas = async () => {
    try {
      const { data } = await this.api.get("/vendas/");
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getAuditorias = async () => {
    try {
      const { data } = await this.api.get("/auditoria/");
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  deleteVenda = async (vendaData, userId) => {
    try {
      const { data } = await this.api.put("/vendas/delete/", vendaData, userId);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  deleteCompra = async (compraData, userId) => {
    try {
      const { data } = await this.api.put(
        "/compras/delete/",
        compraData,
        userId
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  devolverVenda = async (vendaID, userId) => {
    try {
      const { data } = await this.api.put("/vendas/devolver/", vendaID, userId);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getUserNav = async (userId) => {
    try {
      const { data } = await this.api.get(`/user/${userId}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getUsers = async () => {
    try {
      const { data } = await this.api.get(`/user/`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getUserData = async (user_Id) => {
    try {
      const { data } = await this.api.get(`/user/edit/`, user_Id);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  editUser = async (userDataEdit, userId) => {
    try {
      const { data } = await this.api.put(`/user/edit/`, userDataEdit, userId);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getCaixasAbertos = async () => {
    try {
      const { data } = await this.api.get(`/caixa/todos-abertos/`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getCaixas = async () => {
    try {
      const { data } = await this.api.get(`/caixas/`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getTransactions = async (caixaId) => {
    try {
      const { data } = await this.api.get(`/transactions/${caixaId}/`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getLancamentosCaixa = async (caixa_id) => {
    try {
      const { data } = await this.api.get(
        `/lancamentos/meu-caixa-id/${caixa_id}/`
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getCaixasAtivos = async () => {
    try {
      const { data } = await this.api.get(`/caixas/ativos/`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  fechandoCaixa = async (caixa_id) => {
    try {
      const { data } = await this.api.get(`/caixa/fechando/${caixa_id}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  fecharCaixa = async (saldoCaixaDb, userId, caixaId, dataVendas) => {
    try {
      const { data } = await this.api.put(
        `/caixa/fechar/`,
        saldoCaixaDb,
        userId,
        caixaId,
        dataVendas
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  addCaixas = async (caixaData, userId) => {
    try {
      const { data } = await this.api.post(`/caixas/new/`, caixaData, userId);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getCaixaData = async (caixa_id) => {
    try {
      const { data } = await this.api.get(`/caixas/edit/${caixa_id}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  editCaixa = async (caixaData, userId) => {
    try {
      const { data } = await this.api.put(`/caixas/edit/`, caixaData, userId);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  abrirCaixa = async (userId, selectedDate) => {
    try {
      const { data } = await this.api.post(
        `/caixa/abrir/`,
        userId,
        selectedDate
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  deleteProduto = async (produtoID) => {
    try {
      const { data } = await this.api.delete(`/produtos/delete/${produtoID}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  editProduto = async (produtoID, editedData) => {
    try {
      const { data } = await this.api.put(
        `/produtos/edit/${produtoID}`,
        editedData
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getProdutosClientes = async (clienteId, vendas) => {
    try {
      const { data } = await this.api.post(
        `/imei/vendidos/cliente/${clienteId}`,
        vendas
      );
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  // addPriceProduto = async (transactionID, valorCompra, priceVendaDb) => {
  //   try {
  //     const { data } = await this.api.put(
  //       `/transactions/add-price/compra-id/${buy_id}`,
  //       priceDb,
  //       priceVendaDb
  //     );
  //     return data;
  //   } catch (error) {
  //     throw error.response.data.msg;
  //   }
  // };
  getProdutoID = async (compraID) => {
    try {
      const { data } = await this.api.get(`/produtos/compraID/${compraID}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };

  // editCompra = async (compraID, editedData) => {
  //   try {
  //     const { data } = await this.api.put(
  //       `/compras/edit/${compraID}`,
  //       editedData
  //     );
  //     return data;
  //   } catch (error) {
  //     throw error.response.data.msg;
  //   }
  // };
  // getCompraByID = async (compraID) => {
  //   try {
  //     const { data } = await this.api.get(`/compras/${compraID}`);
  //     return data;
  //   } catch (error) {
  //     throw error.response.data.msg;
  //   }
  // };

  getImeisByProdutoId = async (produtoId) => {
    try {
      const { data } = await this.api.get(`/imei/by-produto-id/${produtoId}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  getClienteByID = async (clienteId) => {
    try {
      const { data } = await this.api.get(`/clientes/${clienteId}`);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  editCliente = async (clienteData) => {
    try {
      const { data } = await this.api.put(`/clientes/edit/`, clienteData);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
}

export default new Api();
