import React, { useEffect, useState } from "react";
import api from "../utils/api.utils";
import ImeiReader from "../components/ImeiReaderVenda";
import PartesSearch from "../components/PartesSearch";
import SearchProdutoVenda from "../components/SearchProdutoVenda";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import ModalAddParte from "../components/ModalAddParte";
import { Button, TextField } from "@mui/material";

const AddTransactionVenda = ({
  setMessage,
  error,
  setError,
  userData,
  setLoading,
  loading
}) => {
  const navigate = useNavigate();
  const [sellDate, setSellDate] = useState("");

  const [parte, setParte] = useState(null);

  const [imeiArray, setImeiArray] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [hasImei, setHasImei] = useState("nao");

  const [valorVenda, setValorVenda] = useState(0);
  const [valorOutros, setValorOutros] = useState(0);

  const [valorTotal, setValorTotal] = useState(0);

  const [erroImei, setErrorImei] = useState(null);

  const [dataPagamento, setDataPagamento] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");

  const [selectedDate] = useState(getCurrentFormattedDate());
  const [caixaDiario, setCaixaDiario] = useState(null);

  const handleImeiAdd = async (imei) => {
    try {
      const getImei = await api.buscarImeiDadosVenda(imei);
      if (getImei) {
        setErrorImei(null);
        const isImeiAlreadyAdded = imeiArray.some(
          (existingImei) => existingImei.number === getImei.number
        );

        if (!isImeiAlreadyAdded) {
          let valorVenda = 0;
          let valorCompra = 0;
          if (getImei.transactions && getImei.transactions.length > 0) {
            const lastTransaction =
              getImei.transactions[getImei.transactions.length - 1];
            const produto = lastTransaction.produtos.find(
              (produto) => produto.imeiId === getImei._id
            );
            if (produto) {
              valorVenda = parseFloat(produto.valorVenda) || 0;
              valorCompra = parseFloat(produto.valorCompra);
            }
          }
          getImei.valorVenda = valorVenda;
          getImei.valorCompra = valorCompra;
          setImeiArray([getImei, ...imeiArray]);

          const totalValue = sumImeis();
          setValorVenda(parseFloat(totalValue));
          handleTotalValueUpdate();
        } else {
          setErrorImei("IMEI já foi incluso na lista");
        }
      } else {
        setErrorImei("Erro ao buscar IMEI");
      }
    } catch (error) {
      setErrorImei(error);
      console.error(error);
    }
  };

  const removeImei = (index) => {
    const updatedImeiArray = [...imeiArray];
    updatedImeiArray.splice(index, 1);
    setImeiArray(updatedImeiArray);
  };

  const formatarValor = (valor) => {
    const valorFormatado = valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return valorFormatado;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!parte) {
      setError("Necessário informar o cliente!");
      return;
    }

    if (imeiArray.length < 1 && selectedProducts.length < 1) {
      setError(
        "Necessário informar ao menos 01 produto com ou sem IMEI!"
      );
      return;
    }

    if (!valorTotal > 0) {
      setError("O valor da venda precisa ser maior que Zero!");
      return;
    }

    if (!formaPagamento) {
      setError("Forma de pagamento é obrigatória!");
      return;
    }
    let parteId = parte._id;

    if (formaPagamento === "saldo_carteira" && parte.carteira < valorTotal) {
      setError(
        "A parte não possui saldo disponível para realizar essa transação!"
      );
    } else {
      if (caixaDiario.msg) {
        setError("Necessário abrir caixa antes de realizar venda.");
      } else {
        const { _id } = caixaDiario;
        const idCaixa = _id;
        try {
          await api.addVenda({
            sellDate,
            parteId,
            imeiArray,
            selectedProducts,
            valorTotal,
            userData,
            dataPagamento,
            formaPagamento,
            idCaixa
          });
          // Em seguida, limpo o formulário e fecho o modal.
          setSellDate("");
          setParte(null);
          setImeiArray([]);
          setSelectedProducts([]);
          setValorVenda("");
          setMessage("Venda cadastrada com sucesso!");
          navigate("/");
          setTimeout(() => {
            setMessage("");
          }, 4000);
        } catch (error) {
          console.log(error);
          setError(error);
        }
      }
    }
  };

  const sumImeis = () => {
    return imeiArray
      .reduce((total, imei) => {
        return total + (imei.transactions[0] ? imei.valorVenda : 0);
      }, 0)
      .toFixed(2);
  };

  function getCurrentFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Pad the month and day with leading zeroes if needed
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  //ATUALIZA VALOR DOS OUTROS PRODUTOS
  const handleSellingPriceChange = (updatedProducts, quantity) => {
    // Calcular o valor total com base nos preços de venda atualizados
    const totalValue = calculateTotalValue(updatedProducts);

    // Atualizar os estados
    setValorOutros(totalValue);
  };

  //CALCULA O VALOR DOS OUTROS PRODUTOS PELA QUANTIDADE
  const calculateTotalValue = (products) => {
    return products.reduce((total, product) => {
      return (
        total +
        (product.sellingPrice
          ? parseFloat(product.sellingPrice) * product.quantity
          : 0)
      );
    }, 0);
  };

  const handleTotalValueUpdate = () => {
    const totalValue = parseFloat(valorVenda) + parseFloat(valorOutros);
    setValorTotal(totalValue);
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorImei(null);
    }, 3000);
  });

  useEffect(() => {
    const getCaixa = async () => {
      try {
        setLoading(true);

        if (selectedDate) {
          const getCaixaDia = await api.checkCaixaAberto(selectedDate);
          setCaixaDiario(getCaixaDia);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (userData) {
      getCaixa();
    }
    setLoading(false);
  }, [selectedDate, userData]);

  useEffect(() => {
    const totalValue = sumImeis();
    setValorVenda(parseFloat(totalValue));
    handleTotalValueUpdate(); // Ensure that this call is not happening before setValorVenda completes
  }, [imeiArray, selectedProducts]);

  useEffect(() => {
    handleTotalValueUpdate();
  }, [valorOutros, valorVenda]);

  const [statusModal, setStatusModal] = useState(false);

  const showModalAdd = () => {
    setStatusModal(true);
  };

  return (
    <>
      <div
        className="d-flex flex-column px-3 page"
        style={{ marginTop: "70px", marginBottom: "100px" }}
      >
        <h5 className="mt-3">
          <i className="bi bi-cash-coin"></i> Cadastrando Venda
        </h5>

        <div className="d-flex">
          <div className="d-flex flex-column w-100 p-3">
            <form className="d-flex flex-column ">
              <div className="d-flex flex-wrap justify-content-between  align-items-center">
                <div className=" col-12 col-lg-2">
                  <TextField
                    label="Data da venda"
                    InputLabelProps={{ shrink: true }}
                    value={sellDate}
                    onChange={(e) => setSellDate(e.target.value)}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <PartesSearch
                    title="Cliente"
                    parte={parte}
                    setParte={setParte}
                    setError={setError}
                    error={error}
                  />
                </div>
                <div className=" col-12 col-lg-3">
                  <Button variant="contained" onClick={showModalAdd}>
                    Cadastrar Cliente
                  </Button>
                </div>
              </div>

              <div className="w-100 d-flex justify-content-between">
                <div className="w-100">
                  <ImeiReader onImeiAdd={handleImeiAdd} />
                  {erroImei && (
                    <div className="alert alert-danger">{erroImei}</div>
                  )}

                  <div className="border p-2 rounded bg-light mb-3">
                    <label>IMEIs adicionados a venda:</label>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}></th>
                          <th style={{ width: "5%" }}></th>
                          <th style={{ width: "20%" }}>IMEI</th>
                          <th style={{ width: "35%" }}>Descrição</th>
                          <th style={{ width: "20%", textAlign: "center" }}>
                            Preço de Venda
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {imeiArray &&
                          imeiArray.map((imei, index) => {
                            return (
                              <tr key={index} className="lista-imeis w-100">
                                <td>
                                  <div
                                    className="btn btn-danger"
                                    style={{ width: "auto" }}
                                    onClick={() => removeImei(index)}
                                  >
                                    <i className="bi bi-trash"></i>
                                  </div>
                                </td>
                                <td>{imeiArray.length - index}</td>
                                <td>{imei.number && imei.number}</td>
                                <td>{imei && imei.produtoId.descricao}</td>

                                <td className="text-center bg-light">
                                  <div className="input-group">
                                    <span className="input-group-text">R$</span>

                                    <NumericFormat
                                      className="form-control"
                                      value={imei.valorVenda}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      allowNegative={false}
                                      onValueChange={(values) => {
                                        const updatedImeiArray = [...imeiArray];
                                        updatedImeiArray[index].valorVenda =
                                          parseFloat(values.value) || 0;
                                        setImeiArray(updatedImeiArray);
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <hr />
              <div className="w-100 d-flex justify-content-between">
                <div className="form-group">
                  <label htmlFor="hasImei">
                    Deseja incluir algum produto que não tem IMEI/Serial?
                  </label>
                  <div>
                    <input
                      type="radio"
                      id="hasImei"
                      name="hasImei"
                      value="sim"
                      checked={hasImei === "sim"}
                      onChange={(e) => setHasImei(e.target.value)}
                    ></input>
                    <label htmlFor="sim" className="mx-3">
                      Sim
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="hasImei"
                      name="hasImei"
                      value="nao"
                      checked={hasImei === "nao"}
                      onChange={(e) => setHasImei(e.target.value)}
                    ></input>
                    <label htmlFor="nao" className="mx-3">
                      Não
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-between">
                {hasImei === "sim" ? (
                  <>
                    <SearchProdutoVenda
                      setError={setError}
                      error={error}
                      onSellingPriceChange={handleSellingPriceChange}
                      selectedProducts={selectedProducts}
                      setSelectedProducts={setSelectedProducts}
                      formatarValor={formatarValor}
                    />
                  </>
                ) : null}
              </div>
              <div className="w-100 d-flex justify-content-between">
                <div className="form-group col-md-2">
                  <label htmlFor="dataPagamento">Data de Pagamento</label>
                  <input
                    type="date"
                    className="form-control"
                    id="dataPagamento"
                    name="dataPagamento"
                    value={dataPagamento}
                    onChange={(e) => setDataPagamento(e.target.value)}
                  />
                </div>

                <div className="form-group col-md-8">
                  <label htmlFor="formaPagamento">Forma de Pagamento</label>
                  <select
                    className="form-control"
                    id="formaPagamento"
                    name="formaPagamento"
                    value={formaPagamento}
                    onChange={(e) => setFormaPagamento(e.target.value)}
                  >
                    <option value="">Selecione a forma de pagamento</option>
                    <option value="cartao_credito">Cartão de Crédito</option>
                    <option value="cartao_debito">Cartão de Débito</option>
                    <option value="dinheiro">Dinheiro</option>
                    <option value="pix">PIX</option>
                    <option value="transferencia_bancaria">
                      Transferência Bancária
                    </option>
                    <option value="saldo_carteira">Saldo da carteira</option>
                  </select>
                </div>
              </div>
              <div className="text-center valorVenda rounded mb-3 p-3">
                <div>Valor total da venda</div>
                <div>R$ {valorTotal && formatarValor(valorTotal)}</div>
              </div>
            </form>
            {error ? (
              <div className="alert alert-danger text-center">
                <b>{error}</b>
              </div>
            ) : null}
            {formaPagamento === "saldo_carteira" &&
              parte &&
              parte.carteira < valorTotal && (
                <div className="alert alert-danger text-center">
                  <b>
                    A parte não possui saldo disponível para realizar essa
                    transação!
                  </b>
                </div>
              )}
            <div className="d-flex flex-column align-items-end">
              {!loading && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Cadastrar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*MODAL ADD PARTE*/}
      <ModalAddParte
        title="cliente"
        statusModal={statusModal}
        setStatusModal={setStatusModal}
      />
    </>
  );
};

export default AddTransactionVenda;
