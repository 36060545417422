import React, { createContext, useContext, useState, useEffect } from "react";
import api from "../utils/api.utils";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");

    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
      setIsLogged(true);
    } else {
      setIsLogged(false);
      setLoading(false);
      navigate("/", {
        state: { message: "Sessão expirada. Faça login novamente." },
      });
    }
  }, [navigate]);


  const login = async ({ username, password }, e) => {
    try {
      setLoading(true);
      const response = await api.login({ username, password });
      setUserData(response);
      setIsLogged(true);
      localStorage.setItem("userData", JSON.stringify(response));
      setIsAdmin(response.admin);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      setLoading(false);
      setUserData({});
      throw error;
    }
  };
  const logout = () => {
    setUserData(null);
    setIsLogged(false);
    localStorage.clear();
    navigate("/", { state: { message: "Logout realizado com sucesso" } });
  };

  return (
    <AuthContext.Provider
      value={{
        userData,
        login,
        logout,
        isLogged,
        setIsLogged,
        loading,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
