import React, { useEffect, useState } from "react";
import api from "../utils/api.utils";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";

const AddProduto = ({ message, setMessage, userId }) => {
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [hasImei, setHasImei] = useState("sim");

  //formulario de registro da compra
  const [produtoData, setProdutoData] = useState({
    descricao: "",
    marca: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProdutoData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleImei = (e) => {
    setHasImei(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (produtoData.descricao && produtoData.marca) {
      try {
        const newProduto = await api.addProduto({
          produtoData,
          hasImei,
          valorCompraDb,
          valorVendaDb,
          userId
        });
        if (newProduto) {
          setProdutoData({
            descricao: "",
            marca: "",
            valorCompra: "",
            valorVenda: ""
          });
          setMessage("Produto cadastrado com sucesso!");
          navigate("/produtos/");
        }
      } catch (error) {
        const newError = error.trim().split(" ");

        if (newError[0] === "E11000") {
          setError("Essa descrição já foi cadastrada!");
        } else {
          setError(error);
        }
      }
    } else {
      setError("É necessário informar Descrição e Marca.");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 4000);
  }, []);

  const [valorCompra, setValorCompra] = useState(null);
  const [valorVenda, setValorVenda] = useState(null);

  const handleValorChange = (e) => {
    const inputValor = e.target.value;
    const valorNumerico = parseFloat(inputValor.replace(/[^0-9]/g, "")) / 100;

    setValorCompraDb(valorNumerico);

    if (!isNaN(valorNumerico)) {
      setValorCompra(
        valorNumerico.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      );
    } else {
      setValorCompra("");
    }
  };

  const [valorVendaDb, setValorVendaDb] = useState(0);
  const [valorCompraDb, setValorCompraDb] = useState(0);

  const handleValorVendaChange = (e) => {
    const inputValor = e.target.value;

    const valorNumericoVenda =
      parseFloat(inputValor.replace(/[^0-9]/g, "")) / 100;

    setValorVendaDb(valorNumericoVenda);

    if (!isNaN(valorNumericoVenda)) {
      setValorVenda(
        valorNumericoVenda.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      );
    } else {
      setValorVenda("");
    }
  };

  return (
    <div
      className="p-3 d-flex flex-column page"
      style={{ marginTop: "70px", marginBottom: "100px" }}
    >
      <div className="d-flex flex-column container mt-3">
        <h5 className="mt-3">
          <i className="bi bi-cash-coin"></i> Cadastrando Produto
        </h5>

        <form className="d-flex flex-wrap  justify-content-between">
          <div className="form-group col-12">
            <label htmlFor="descricao">Descrição:</label>
            <input
              type="text"
              className="form-control"
              id="descricao"
              name="descricao"
              placeholder="Ex. iPhone 12 Pro Max 256GB"
              value={produtoData.descricao}
              onChange={handleChange}
            />
          </div>

          <div className="form-group col-12">
            <label htmlFor="marca">Marca:</label>
            <input
              type="text"
              className="form-control"
              id="marca"
              name="marca"
              placeholder="Ex. Apple"
              value={produtoData.marca}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-12 col-lg-4">
            <label htmlFor="hasImei">O produto possui IMEI/Serial?</label>
            <div>
              <input
                type="radio"
                id="hasImei"
                name="hasImei"
                value="sim"
                checked={hasImei === "sim"}
                onChange={handleImei}
              ></input>
              <label htmlFor="sim" className="mx-3">
                Sim
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="hasImei"
                name="hasImei"
                value="nao"
                checked={hasImei === "nao"}
                onChange={handleImei}
              ></input>
              <label htmlFor="nao" className="mx-3">
                Não
              </label>
            </div>
          </div>
          {hasImei !== "sim" ? (
            <>
              <div className="col-12 col-lg-1">
                <label htmlFor="marca">Quantidade:</label>
                <input
                  type="number"
                  className="form-control"
                  id="qtd"
                  name="qtd"
                  value={produtoData.qtd}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-2">
                <label htmlFor="marca">Valor de compra:</label>
                <InputMask
                  mask=""
                  maskChar=""
                  alwaysShowMask={false}
                  id="valorCompra"
                  name="valorCompra"
                  value={valorCompra}
                  placeholder="0,00"
                  onChange={handleValorChange}
                  className="form-control"
                />
              </div>
              <div className="col-12 col-lg-2">
                <label htmlFor="marca">Valor de venda:</label>
                <InputMask
                  mask=""
                  maskChar=""
                  alwaysShowMask={false}
                  id="valorVenda"
                  name="valorVenda"
                  value={valorVenda}
                  placeholder="0,00"
                  onChange={handleValorVendaChange}
                  className="form-control"
                />
              </div>
            </>
          ) : null}
        </form>
        {error && (
          <div className="alert alert-danger text-center">
            <strong>{error}</strong>
          </div>
        )}
        <div className="d-flex flex-column align-items-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Cadastrar
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddProduto;
